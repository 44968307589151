var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/coaching-challenge"
    }
  }, [_vm._v(" Manajemen Diet Coaching & Challenge ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Ubah" : "Tambah") + " Diet Coaching & Challenge ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px",
      "mb": "50px"
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Ubah" : "Tambah") + " Konten ")]), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-w": "590px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.type === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tipe Konten ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "disabled": _vm.id,
      "variant": _vm.id ? 'filled' : '',
      "placeholder": "Masukkan Tipe Konten",
      "height": "62px"
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }, [_c('option', {
    attrs: {
      "value": "daily_coaching"
    }
  }, [_vm._v(" Daily Coaching ")]), _c('option', {
    attrs: {
      "value": "coaching_course"
    }
  }, [_vm._v(" Coaching Course ")]), _c('option', {
    attrs: {
      "value": "diet_challenge"
    }
  }, [_vm._v(" Diet Challenge ")])])], 1)], 1), _vm.type !== 'coaching_course' ? _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.type !== 'coaching_course' && _vm.showOnDay === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tampilkan Hari Ke ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "min": "0",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Tampilkan Hari Ke",
      "height": "62px"
    },
    model: {
      value: _vm.showOnDay,
      callback: function callback($$v) {
        _vm.showOnDay = $$v;
      },
      expression: "showOnDay"
    }
  })], 1)], 1) : _vm._e(), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.title === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Judul Konten ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Judul Konten",
      "height": "62px"
    },
    model: {
      value: _vm.title,
      callback: function callback($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "min-w": "180px",
      "w": "180px",
      "mr": "40px",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Preview Cover Program ")]), _c('label', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        bg: 'white',
        cursor: 'pointer',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '6px',
        w: '180px',
        h: '100%',
        minHeight: '180px',
        d: 'flex',
        p: '0',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      },
      expression: "{\n          bg: 'white',\n          cursor: 'pointer',\n          border: '1px solid #C4C4C4',\n          boxSizing: 'border-box',\n          borderRadius: '6px',\n          w: '180px',\n          h: '100%',\n          minHeight: '180px',\n          d: 'flex',\n          p: '0',\n          alignItems: 'center',\n          justifyContent: 'center',\n          overflow: 'hidden',\n        }"
    }],
    attrs: {
      "for": "img-preview"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPreviewImage(_vm.imagePreview[0].url),
      "h": !_vm.getPreviewImage(_vm.imagePreview[0].url).includes('icon-photo') ? '100%' : '94px',
      "alt": "img program",
      "mx": "auto",
      "border-radius": "6px"
    }
  })], 1), _c('c-input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "img-preview",
      "type": "file",
      "accept": ".jpg, .jpeg, .png",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Deskripsi Konten",
      "height": "62px"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event, 'preview');
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.description === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Deskripsi Konten ")]), _c('wysiwyg', {
    attrs: {
      "placeholder": "Masukkan Deskripsi Konten"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1), _vm._l(_vm.images, function (item, indexImg) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.preview,
        expression: "!item.preview"
      }],
      key: 'image' + indexImg,
      attrs: {
        "position": "relative",
        "mb": "25px"
      }
    }, [_c('c-box', {
      attrs: {
        "position": "absolute",
        "top": "50%",
        "right": "-45px",
        "transform": "translateY(-50%)"
      }
    }, [_c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.removeMedia(item, indexImg);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty"
      }
    })], 1)], 1), _c('c-flex', {
      attrs: {
        "align": "baseline"
      }
    }, [_c('c-form-control', {
      attrs: {
        "min-w": "180px",
        "w": "180px",
        "mr": "40px",
        "is-invalid": true
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Gambar Program ")]), _c('label', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          bg: 'white',
          cursor: 'pointer',
          border: '1px solid #C4C4C4',
          boxSizing: 'border-box',
          borderRadius: '6px',
          w: '180px',
          h: '100%',
          minHeight: '180px',
          d: 'flex',
          p: '0',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        },
        expression: "{\n              bg: 'white',\n              cursor: 'pointer',\n              border: '1px solid #C4C4C4',\n              boxSizing: 'border-box',\n              borderRadius: '6px',\n              w: '180px',\n              h: '100%',\n              minHeight: '180px',\n              d: 'flex',\n              p: '0',\n              alignItems: 'center',\n              justifyContent: 'center',\n              overflow: 'hidden',\n            }"
      }],
      attrs: {
        "for": 'img-program-' + indexImg
      }
    }, [_c('c-image', {
      attrs: {
        "src": _vm.getPreviewImage(item.url),
        "h": !_vm.getPreviewImage(item.url).includes('icon-photo') ? '100%' : '94px',
        "alt": "img program",
        "mx": "auto",
        "border-radius": "6px"
      }
    })], 1), _c('c-input', {
      staticStyle: {
        "display": "none"
      },
      attrs: {
        "id": 'img-program-' + indexImg,
        "type": "file",
        "accept": ".jpg, .jpeg, .png",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Deskripsi Konten",
        "height": "62px"
      },
      on: {
        "change": function change($event) {
          return _vm.onFileChange($event, indexImg);
        }
      }
    })], 1), _c('c-form-control', {
      attrs: {
        "w": "100%",
        "is-invalid": true
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Caption Gambar ")]), _c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.caption,
        expression: "item.caption"
      }, {
        name: "chakra",
        rawName: "v-chakra",
        value: {
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset'
          }
        },
        expression: "{\n              border: '1px solid #C4C4C4',\n              padding: '18px',\n              width: '100%',\n              borderRadius: '6px',\n              height: '180px',\n              '&:focus, &:focus-visible': {\n                outline: 'unset',\n              },\n            }"
      }],
      attrs: {
        "rows": "8",
        "placeholder": "Masukkan Caption Gambar"
      },
      domProps: {
        "value": item.caption
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "caption", $event.target.value);
        }
      }
    })], 1)], 1)], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.isDisabledAddImg,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "mb": "25px",
      "left-icon": "add"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addMediaImages.apply(null, arguments);
      }
    }
  }, [_vm._v(" Tambah Gambar Lain ")]), _vm._l(_vm.videos, function (item, index) {
    return _c('c-box', {
      key: 'video' + index,
      attrs: {
        "position": "relative",
        "mb": "25px"
      }
    }, [_c('c-flex', {
      attrs: {
        "align": "baseline"
      }
    }, [_c('c-form-control', {
      attrs: {
        "min-w": "180px",
        "w": "180px",
        "mr": "40px",
        "is-invalid": true
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Video Program ")]), _c('label', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          bg: 'white',
          cursor: 'pointer',
          border: '1px solid #C4C4C4',
          boxSizing: 'border-box',
          borderRadius: '6px',
          w: '180px',
          h: '180px',
          d: 'flex',
          p: '0',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        },
        expression: "{\n              bg: 'white',\n              cursor: 'pointer',\n              border: '1px solid #C4C4C4',\n              boxSizing: 'border-box',\n              borderRadius: '6px',\n              w: '180px',\n              h: '180px',\n              d: 'flex',\n              p: '0',\n              alignItems: 'center',\n              justifyContent: 'center',\n              overflow: 'hidden',\n            }"
      }],
      attrs: {
        "for": "video-program"
      }
    }, [_vm.getPreviewVideo(item.url).includes('icon-player-play') ? _c('c-image', {
      attrs: {
        "src": _vm.getPreviewVideo(item.url),
        "alt": "video program",
        "w": "94px",
        "mx": "auto",
        "border-radius": "6px"
      }
    }) : _c('video', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "w": "100%",
        "h": "100%",
        "src": _vm.media.url,
        "controls": "",
        "autoplay": "false",
        "muted": "muted"
      },
      domProps: {
        "muted": true
      }
    })], 1), _c('c-input', {
      staticStyle: {
        "display": "none"
      },
      attrs: {
        "id": "video-program",
        "type": "file",
        "accept": ".mp4",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Deskripsi Konten",
        "height": "62px"
      },
      on: {
        "change": function change($event) {
          return _vm.onFileChange($event, index);
        }
      }
    })], 1), _c('c-form-control', {
      attrs: {
        "w": "100%",
        "is-invalid": true
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Caption Video ")]), _c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.caption,
        expression: "item.caption"
      }, {
        name: "chakra",
        rawName: "v-chakra",
        value: {
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset'
          }
        },
        expression: "{\n              border: '1px solid #C4C4C4',\n              padding: '18px',\n              width: '100%',\n              borderRadius: '6px',\n              height: '180px',\n              '&:focus, &:focus-visible': {\n                outline: 'unset',\n              },\n            }"
      }],
      attrs: {
        "rows": "8",
        "placeholder": "Masukkan Caption Video"
      },
      domProps: {
        "value": item.caption
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "caption", $event.target.value);
        }
      }
    })], 1)], 1)], 1);
  }), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.category === '',
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kategori ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukkan Kategori",
      "height": "62px"
    },
    model: {
      value: _vm.category,
      callback: function callback($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }, _vm._l(_vm.categories, function (item) {
    return _c('option', {
      key: item.value,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0)], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Link YouTube ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Link YouTube",
      "height": "62px"
    },
    model: {
      value: _vm.linkYoutube,
      callback: function callback($$v) {
        _vm.linkYoutube = $$v;
      },
      expression: "linkYoutube"
    }
  })], 1)], 1), _vm.type !== 'coaching_course' ? _c('c-box', [_vm._l(_vm.files, function (item, index) {
    return _c('c-box', {
      key: 'file' + index,
      attrs: {
        "position": "relative",
        "mb": "25px"
      }
    }, [_c('c-box', {
      attrs: {
        "position": "absolute",
        "top": "50%",
        "right": "-45px",
        "transform": "translateY(-50%)"
      }
    }, [_c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.removeMedia(item, index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty"
      }
    })], 1)], 1), _c('c-flex', {
      attrs: {
        "align": "baseline"
      }
    }, [_c('c-form-control', {
      attrs: {
        "min-w": "180px",
        "w": "180px",
        "mr": "40px",
        "is-invalid": true
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Upload File ")]), _c('label', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          bg: 'white',
          cursor: 'pointer',
          border: '1px solid #C4C4C4',
          boxSizing: 'border-box',
          borderRadius: '6px',
          w: '180px',
          h: '180px',
          d: 'flex',
          p: '0',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        },
        expression: "{\n                bg: 'white',\n                cursor: 'pointer',\n                border: '1px solid #C4C4C4',\n                boxSizing: 'border-box',\n                borderRadius: '6px',\n                w: '180px',\n                h: '180px',\n                d: 'flex',\n                p: '0',\n                alignItems: 'center',\n                justifyContent: 'center',\n                overflow: 'hidden',\n              }"
      }],
      attrs: {
        "for": 'file-program-' + index
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-folder-upload.svg'),
        "alt": "file program",
        "w": "94px",
        "mx": "auto",
        "border-radius": "6px"
      }
    })], 1), _c('c-input', {
      staticStyle: {
        "display": "none"
      },
      attrs: {
        "id": 'file-program-' + index,
        "type": "file",
        "accept": ".pdf,.doc,.docx,application/msword",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Deskripsi Konten",
        "height": "62px"
      },
      on: {
        "change": function change($event) {
          return _vm.onFileChange($event, index);
        }
      }
    })], 1), _c('c-form-control', {
      attrs: {
        "align-self": "center",
        "w": "100%",
        "is-invalid": true
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "gray.900",
        "font-weigh": "normal",
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(_vm.getPreviewFile(item)) + " ")])], 1)], 1)], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.isDisabledAddFile,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "mb": "25px",
      "left-icon": "add"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addMediaFiles.apply(null, arguments);
      }
    }
  }, [_vm._v(" Tambah File Lain ")])], 2) : _vm._e(), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "gray",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px",
      "color": "#555555"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "font-size": "18px",
      "is-disabled": _vm.isDisabledSubmit
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Simpan ")])], 1)], 2), _vm.isUploading ? _c('c-alert', {
    attrs: {
      "position": "fixed",
      "top": "5px",
      "left": "50%",
      "transform": "translateX(-50%)",
      "border-radius": "3px",
      "w": "200px",
      "status": "info",
      "font-size": "14px",
      "z-index": "9999"
    }
  }, [_c('c-alert-icon'), _vm._v(" Uploading... ")], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }