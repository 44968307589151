<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="primary.400"
          as="router-link"
          to="/management/coaching-challenge"
        >
          Manajemen Diet Coaching & Challenge
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          {{ id ? "Ubah" : "Tambah" }} Diet Coaching & Challenge
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-heading
      as="h3"
      font-family="Montserrat"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      mb="50px"
    >
      {{ id ? "Ubah" : "Tambah" }} Konten
    </c-heading>

    <form
      v-chakra
      max-w="590px"
    >
      <c-form-control
        mb="25px"
        is-required
        :is-invalid="type === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Tipe Konten
        </c-form-label>
        <c-input-group size="md">
          <c-select
            v-model="type"
            :disabled="id"
            :variant="id ? 'filled' : ''"
            placeholder="Masukkan Tipe Konten"
            height="62px"
          >
            <option value="daily_coaching">
              Daily Coaching
            </option>
            <option value="coaching_course">
              Coaching Course
            </option>
            <option value="diet_challenge">
              Diet Challenge
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-form-control
        v-if="type !== 'coaching_course'"
        :is-invalid="type !== 'coaching_course' && showOnDay === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Tampilkan Hari Ke
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="showOnDay"
            type="number"
            min="0"
            error-border-color="red.300"
            placeholder="Masukkan Tampilkan Hari Ke"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        :is-invalid="title === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Judul Konten
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="title"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Judul Konten"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        min-w="180px"
        w="180px"
        mr="40px"
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Preview Cover Program
        </c-form-label>
        <label
          v-chakra="{
            bg: 'white',
            cursor: 'pointer',
            border: '1px solid #C4C4C4',
            boxSizing: 'border-box',
            borderRadius: '6px',
            w: '180px',
            h: '100%',
            minHeight: '180px',
            d: 'flex',
            p: '0',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }"
          for="img-preview"
        >
          <c-image
            :src="getPreviewImage(imagePreview[0].url)"
            :h="
              !getPreviewImage(imagePreview[0].url).includes('icon-photo')
                ? '100%'
                : '94px'
            "
            alt="img program"
            mx="auto"
            border-radius="6px"
          />
        </label>
        <c-input
          id="img-preview"
          style="display: none"
          type="file"
          accept=".jpg, .jpeg, .png"
          error-border-color="red.300"
          placeholder="Masukkan Deskripsi Konten"
          height="62px"
          @change="onFileChange($event, 'preview')"
        />
      </c-form-control>

      <c-form-control
        :is-invalid="description === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Deskripsi Konten
        </c-form-label>
        <wysiwyg
          v-model="description"
          placeholder="Masukkan Deskripsi Konten"
        />
        <!-- <textarea
          rows="8"
          placeholder="Masukkan Deskripsi Konten"
          v-chakra="{
            border: '1px solid #C4C4C4',
            padding: '18px',
            width: '100%',
            borderRadius: '6px',
            height: '151px',
            '&:focus, &:focus-visible': {
              outline: 'unset',
            },
          }"
          v-model="description"
        ></textarea> -->
      </c-form-control>

      <c-box
        v-for="(item, indexImg) in images"
        v-show="!item.preview"
        :key="'image' + indexImg"
        position="relative"
        mb="25px"
      >
        <c-box
          position="absolute"
          top="50%"
          right="-45px"
          transform="translateY(-50%)"
        >
          <c-button
            ml="18px"
            min-width="auto"
            variant="link"
            @click="removeMedia(item, indexImg)"
          >
            <c-image
              :src="require('@/assets/icon-trash-line.svg')"
              alt="empty"
            />
          </c-button>
        </c-box>
        <c-flex align="baseline">
          <c-form-control
            min-w="180px"
            w="180px"
            mr="40px"
            :is-invalid="true"
          >
            <c-form-label
              font-size="14px"
              color="#555555"
              font-weigh="400"
              font-family="Roboto"
            >
              Gambar Program
            </c-form-label>
            <label
              v-chakra="{
                bg: 'white',
                cursor: 'pointer',
                border: '1px solid #C4C4C4',
                boxSizing: 'border-box',
                borderRadius: '6px',
                w: '180px',
                h: '100%',
                minHeight: '180px',
                d: 'flex',
                p: '0',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }"
              :for="'img-program-' + indexImg"
            >
              <c-image
                :src="getPreviewImage(item.url)"
                :h="
                  !getPreviewImage(item.url).includes('icon-photo')
                    ? '100%'
                    : '94px'
                "
                alt="img program"
                mx="auto"
                border-radius="6px"
              />
            </label>
            <c-input
              :id="'img-program-' + indexImg"
              style="display: none"
              type="file"
              accept=".jpg, .jpeg, .png"
              error-border-color="red.300"
              placeholder="Masukkan Deskripsi Konten"
              height="62px"
              @change="onFileChange($event, indexImg)"
            />
          </c-form-control>
          <c-form-control
            w="100%"
            :is-invalid="true"
          >
            <c-form-label
              font-size="14px"
              color="#555555"
              font-weigh="400"
              font-family="Roboto"
            >
              Caption Gambar
            </c-form-label>
            <textarea
              v-model="item.caption"
              v-chakra="{
                border: '1px solid #C4C4C4',
                padding: '18px',
                width: '100%',
                borderRadius: '6px',
                height: '180px',
                '&:focus, &:focus-visible': {
                  outline: 'unset',
                },
              }"
              rows="8"
              placeholder="Masukkan Caption Gambar"
            />
          </c-form-control>
        </c-flex>
      </c-box>
      <c-button
        :is-disabled="isDisabledAddImg"
        variant-color="primary"
        variant="outline"
        border-radius="60px"
        h="62px"
        w="100%"
        mb="25px"
        left-icon="add"
        @click.prevent="addMediaImages"
      >
        Tambah Gambar Lain
      </c-button>

      <c-box
        v-for="(item, index) in videos"
        :key="'video' + index"
        position="relative"
        mb="25px"
      >
        <c-flex align="baseline">
          <c-form-control
            min-w="180px"
            w="180px"
            mr="40px"
            :is-invalid="true"
          >
            <c-form-label
              font-size="14px"
              color="#555555"
              font-weigh="400"
              font-family="Roboto"
            >
              Video Program
            </c-form-label>
            <label
              v-chakra="{
                bg: 'white',
                cursor: 'pointer',
                border: '1px solid #C4C4C4',
                boxSizing: 'border-box',
                borderRadius: '6px',
                w: '180px',
                h: '180px',
                d: 'flex',
                p: '0',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }"
              for="video-program"
            >
              <c-image
                v-if="getPreviewVideo(item.url).includes('icon-player-play')"
                :src="getPreviewVideo(item.url)"
                alt="video program"
                w="94px"
                mx="auto"
                border-radius="6px"
              />
              <video
                v-else
                v-chakra
                w="100%"
                h="100%"
                :src="media.url"
                controls
                autoplay="false"
                muted="muted"
              />
            </label>
            <c-input
              id="video-program"
              style="display: none"
              type="file"
              accept=".mp4"
              error-border-color="red.300"
              placeholder="Masukkan Deskripsi Konten"
              height="62px"
              @change="onFileChange($event, index)"
            />
          </c-form-control>
          <c-form-control
            w="100%"
            :is-invalid="true"
          >
            <c-form-label
              font-size="14px"
              color="#555555"
              font-weigh="400"
              font-family="Roboto"
            >
              Caption Video
            </c-form-label>
            <textarea
              v-model="item.caption"
              v-chakra="{
                border: '1px solid #C4C4C4',
                padding: '18px',
                width: '100%',
                borderRadius: '6px',
                height: '180px',
                '&:focus, &:focus-visible': {
                  outline: 'unset',
                },
              }"
              rows="8"
              placeholder="Masukkan Caption Video"
            />
          </c-form-control>
        </c-flex>
      </c-box>

      <c-form-control
        :is-invalid="category === ''"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Kategori
        </c-form-label>
        <c-input-group size="md">
          <c-select
            v-model="category"
            placeholder="Masukkan Kategori"
            height="62px"
          >
            <option
              v-for="item in categories"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </c-select>
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Link YouTube
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="linkYoutube"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Link YouTube"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-box v-if="type !== 'coaching_course'">
        <c-box
          v-for="(item, index) in files"
          :key="'file' + index"
          position="relative"
          mb="25px"
        >
          <c-box
            position="absolute"
            top="50%"
            right="-45px"
            transform="translateY(-50%)"
          >
            <c-button
              ml="18px"
              min-width="auto"
              variant-color="red"
              variant="link"
              @click="removeMedia(item, index)"
            >
              <c-image
                :src="require('@/assets/icon-trash-line.svg')"
                alt="empty"
              />
            </c-button>
          </c-box>
          <c-flex align="baseline">
            <c-form-control
              min-w="180px"
              w="180px"
              mr="40px"
              :is-invalid="true"
            >
              <c-form-label
                font-size="14px"
                color="#555555"
                font-weigh="400"
                font-family="Roboto"
              >
                Upload File
              </c-form-label>
              <label
                v-chakra="{
                  bg: 'white',
                  cursor: 'pointer',
                  border: '1px solid #C4C4C4',
                  boxSizing: 'border-box',
                  borderRadius: '6px',
                  w: '180px',
                  h: '180px',
                  d: 'flex',
                  p: '0',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                }"
                :for="'file-program-' + index"
              >
                <c-image
                  :src="require('@/assets/icon-folder-upload.svg')"
                  alt="file program"
                  w="94px"
                  mx="auto"
                  border-radius="6px"
                />
              </label>
              <c-input
                :id="'file-program-' + index"
                style="display: none"
                type="file"
                accept=".pdf,.doc,.docx,application/msword"
                error-border-color="red.300"
                placeholder="Masukkan Deskripsi Konten"
                height="62px"
                @change="onFileChange($event, index)"
              />
            </c-form-control>
            <c-form-control
              align-self="center"
              w="100%"
              :is-invalid="true"
            >
              <c-form-label
                font-size="14px"
                color="gray.900"
                font-weigh="normal"
                font-family="Roboto"
              >
                {{ getPreviewFile(item) }}
              </c-form-label>
            </c-form-control>
          </c-flex>
        </c-box>
        <c-button
          :is-disabled="isDisabledAddFile"
          variant-color="primary"
          variant="outline"
          border-radius="60px"
          h="62px"
          w="100%"
          mb="25px"
          left-icon="add"
          @click.prevent="addMediaFiles"
        >
          Tambah File Lain
        </c-button>
      </c-box>

      <c-flex
        mt="28px"
        justify-content="space-between"
      >
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="onCancel"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :is-disabled="isDisabledSubmit"
          @click="onSubmit"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
    <c-alert
      v-if="isUploading"
      position="fixed"
      top="5px"
      left="50%"
      transform="translateX(-50%)"
      border-radius="3px"
      w="200px"
      status="info"
      font-size="14px"
      z-index="9999"
    >
      <c-alert-icon />
      Uploading...
    </c-alert>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'SuperadminCoachingChallengeForm',
  components: {},
  mixins: [generalMixin],
  data() {
    return {
      type: this.$route.params.type ?? 'daily_coaching',
      showOnDay: null,
      category: null,
      title: null,
      description: null,
      media: [],
      linkYoutube: null,

      imagePreview: [
        {
          type: 'image',
          url: null,
          preview: true,
          caption: null,
          fileName: null,
          fileExtension: null,
          fileSize: null,
        },
      ],
      images: [],
      videos: [],
      files: [],
      youtube: [],

      isUploading: false,
    }
  },
  computed: {
    ...mapState({
      categoryContent: (s) => s.contentChallenge.category,
      categoryCoaching: (s) => s.contentChallenge.categoryCoaching,
    }),
    ...mapGetters({
      axios: 'axios',
      item: 'contentChallenge/item',
    }),
    id() {
      return this.$route.params.id
    },
    categories() {
      if (this.type === 'coaching_course') {
        return this.categoryCoaching.filter((it) => it.value)
      }
      return this.categoryContent.filter((it) => it.value)
    },
    isDisabledAddImg() {
      let emptyObj = this.images.findIndex((dt) => !dt.url && !dt.caption)
      if (this.images.length == 10 || emptyObj > -1) return true
      return false
    },
    isDisabledAddVideo() {
      let emptyObj = this.videos.findIndex((dt) => !dt.url && !dt.caption)
      if (emptyObj > -1) return true
      return false
    },
    isDisabledAddFile() {
      let emptyObj = this.files.findIndex((dt) => !dt.url && !dt.caption)
      if (this.files.length == 10 || emptyObj > -1) return true
      return false
    },
    isDisabledSubmit() {
      if (
        !this.category ||
        !this.type ||
        !this.title ||
        !this.description ||
        (this.type !== 'coaching_course' && !this.showOnDay)
      )
        return true
      return false
    },
  },
  watch: {
    media: {
      immediate: true,
      handler() {
        if (this.media.length > 0) {
          this.imagePreview = this.media.filter(
            (it) => it.type === 'image' && it.preview,
          )
          this.images = this.media.filter((it) => it.type === 'image')
          this.videos = this.media.filter((it) => it.type === 'video')
          this.files = this.media.filter((it) => it.type === 'file')
          this.youtube = this.media.filter((it) => it.type === 'youtube')
          this.linkYoutube =
            this.youtube.length > 0 ? this.youtube[0].url : null
        }

        if (this.imagePreview.length === 0) {
          this.imagePreview = [
            {
              type: 'image',
              url: null,
              preview: true,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ]
        }

        if (this.images.length === 0) {
          this.images = [
            {
              type: 'image',
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ]
        }
        if (this.videos.length === 0) {
          this.videos = [
            {
              type: 'video',
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ]
        }
        if (this.files.length === 0) {
          this.files = [
            {
              type: 'file',
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ]
        }
        if (this.youtube.length === 0) {
          this.youtube = [
            {
              type: 'youtube',
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ]
        }
      },
    },
    item: {
      handler(val) {
        if (val) {
          this.type = val.type
          this.showOnDay = val.showOnDay
          this.category = val.category
          this.title = val.title
          this.description = val.description
          this.media = val.media
        }
      },
    },
    linkYoutube: {
      handler(val) {
        if (val) {
          this.youtube[0].url = val
        }
      },
    },
    type: {
      immediate: true,
      handler(val) {
        if (val === 'coaching_course') {
          this.files = [
            {
              type: 'file',
              url: null,
              preview: false,
              caption: null,
              fileName: null,
              fileExtension: null,
              fileSize: null,
            },
          ]
        }
      },
    },
  },
  async mounted() {
    if (!this.id) return
    this.detailContentChallenge(this.id)
  },
  methods: {
    ...mapActions({
      createContentChallenge:
        'contentChallenge/createContentChallengeSuperadmin',
      detailContentChallenge:
        'contentChallenge/detailContentChallengeSuperadmin',
      updateContentChallenge:
        'contentChallenge/updateContentChallengeSuperadmin',
    }),
    addMediaImages() {
      if (this.images.length == 10) return
      this.images.push({
        type: 'image',
        url: null,
        preview: false,
        caption: null,
        fileName: null,
        fileExtension: null,
        fileSize: null,
      })
    },
    addMediaFiles() {
      this.files.push({
        type: 'file',
        url: null,
        preview: false,
        caption: null,
        fileName: null,
        fileExtension: null,
        fileSize: null,
      })
    },
    getPreviewImage(url) {
      if (url) {
        return url
      }
      return require('@/assets/icon-photo.svg')
    },
    getPreviewVideo(url) {
      if (url) {
        return url
      }
      return require('@/assets/icon-player-play.svg')
    },
    getPreviewFile(item) {
      if (item.fileName) {
        return item.fileName
      }
      return 'File maksimal 3 MB'
    },
    onFileChange(input, index) {
      let self = this
      if (self.isUploading) return
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0]
        self.isUploading = true
        self.$toast({
          description: 'Uploading...',
          status: 'info',
          duration: null,
          position: 'top',
          variant: 'subtle',
        })
        if (file.type.includes('image') && file.size > 2097152) {
          self.isUploading = false
          return self.$toast({
            title: 'Failed',
            description: `This file can't be uploaded, because it (${self.formatBytes(
              file.size,
            )}) exceeds the maximum file size (2 MB).`,
            status: 'error',
            duration: 10000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }
        // Upload avatar to API
        let formData = new FormData()
        formData.append('file', input.target.files[0])
        for (var pair of formData.entries()) {
          console.log(pair[0] + ', ' + pair[1])
        }

        self.axios
          .post('/v1/super-admin/content-and-challenge/upload', formData)
          .then((it) => it.data)
          .then((it) => {
            self.isUploading = false
            if (index === 'preview' && file.type.includes('image')) {
              self.imagePreview[0].type = 'image'
              self.imagePreview[0].url = it.data.url
              self.imagePreview[0].fileName = it.meta.fileName
              self.imagePreview[0].fileExtension = it.meta.fileExtension
              self.imagePreview[0].fileSize = it.meta.fileSize
              self.imagePreview[0].preview = true
              self.media.push(self.imagePreview[0])
            } else if (file.type.includes('image')) {
              self.images[index].type = 'image'
              self.images[index].url = it.data.url
              self.images[index].fileName = it.meta.fileName
              self.images[index].fileExtension = it.meta.fileExtension
              self.images[index].fileSize = it.meta.fileSize
              self.media.push(self.images[index])
            } else if (file.type.includes('video')) {
              self.videos[index].type = 'video'
              self.videos[index].url = it.data.url
              self.videos[index].fileName = it.meta.fileName
              self.videos[index].fileExtension = it.meta.fileExtension
              self.videos[index].fileSize = it.meta.fileSize
              self.media.push(self.videos[index])
            } else {
              self.files[index].type = 'file'
              self.files[index].url = it.data.url
              self.files[index].fileName = it.meta.fileName
              self.files[index].fileExtension = it.meta.fileExtension
              self.files[index].fileSize = it.meta.fileSize
              self.media.push(self.files[index])
            }

          })
          .catch(() => {
            self.$toast({
              title: 'Failed',
              description: 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
    },
    onSubmit() {
      let filteredImages = this.images.filter((dt) => !dt.preview && dt.url)
      let _images = this.imagePreview.concat(filteredImages)
      let _videos = this.videos.filter((it) => it.url)
      let _files = this.files.filter((it) => it.url)
      _files = _.sortBy(_files, 'url')
      let _youtube = this.youtube.filter((it) => it.url)
      let _media = _images.concat(_videos, _files, _youtube)
      if (this.type === 'coaching_course') {
        _media = _images.concat(_videos, _youtube)
      }
      let params = {
        type: this.type,
        showOnDay: this.showOnDay,
        category: this.category,
        title: this.title,
        description: this.description,
        media: _media,
      }
      if (this.id) {
        params.id = this.id
        return this.updateContentChallenge(params)
          .then(() => {
            this.$toast({
              title: 'Success',
              description: 'Update content is successfully',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.$router.push({
              name: 'superadmin.coaching-challenge.detail',
              params: { id: this.id },
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
      return this.createContentChallenge(params)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Create content is successfully',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'superadmin.coaching-challenge',
            query: { type: this.type },
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
    removeMedia(item) {
      this.imagePreview = this.imagePreview.filter((it) => it.url !== item.url)
      this.media = this.media.filter((it) => it.url !== item.url)
      this.images = this.images.filter((it) => it.url !== item.url)
      this.videos = this.videos.filter((it) => it.url !== item.url)
      this.files = this.files.filter((it) => it.url !== item.url)
    },
    onCancel() {
      if (this.id) {
        this.$router.push({
          name: 'superadmin.coaching-challenge.detail',
          params: { id: this.id },
        })
      } else {
        this.$router.push({
          name: 'superadmin.coaching-challenge',
          query: { type: this.type },
        })
      }
    },
  },
}
</script>
